.popup{
     width: 360px;
     position: absolute;
     height: 180px;
     right: 0;
     top: -200px;
     background-color: #d9d9d9;
     display: flex;
     /* align-items: center; */
     justify-content: center;
     flex-direction: column;
     border-radius: 10px;
     gap: 10px;
     transition: all 150ms ease;
     pointer-events: none;
     opacity: 0;
}

.popup[aria-role="true"]{
    opacity: 1;
    transition: all 150ms ease;
    pointer-events: all;
}

.popup p{
    /* line-height: 0; */
    color: #000000;
    font-size: 16px;
}

.popup .email{
    margin: -0px auto;
    /* text-align: left; */
    color: #000000;
    margin-bottom: 30px;

}

.popup .contactHeading{
    margin: 0px auto;
    color: #000000;

    text-align: center;
}

@media screen and (max-width:800px) {

    .popup{
        width: 320px;
        height: 160px;
        top: -180px;
    }

    .popup .contactHeading{
    margin: -10px auto;

    text-align: left;
}
}

@media screen and (max-width:450px){
     .popup{
        width: 260px;
        height: 130px;
        top: -140px;
    }
}