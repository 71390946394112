.footer{
    background:  transparent;
    min-height:400px;
    width:100%;
    margin: 0;
    padding: 20px;
}

.footer .topHeading{
    font-weight: 700;
    letter-spacing: 1.05px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-top:20px;
    text-align: center;
}

.footerColumnContainer{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    place-items: center;
    width: 90%;
    margin: 50px auto;
    /* background-color: antiquewhite; */
    max-width: 1800px;
     /* background-color: red;*/
}

.footerColumn{
    color: floralwhite;
    font-weight:bold;
    font-size: 1.2rem;
    text-align: left;
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
}

.footerColumn h2{
   color: #221f1a;
   font-size: 20px;
   font-weight: 600;
   margin: 16px auto;
   text-align: left;
}

.footerColumn ul{
    list-style: none;
    margin-top: 5px;
}





.footerColumn ul li{
    color: #fff;
    font-weight: 400;
    padding: 3px 0px;
    font-size: 16px;
}

.linkContainer{
    margin: 80px auto 50px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 85%;
    max-width: 1700px;
    
}

.linkContainer a{
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}


.linkContainer a:before{
    content: "";
   position: absolute;
   bottom: 2px;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: #ffffff;
   opacity: 0.6;

}

.seperator{
    width: 80%;
       height: 3px;
   background-color: #ffffff;
   margin: 30px auto 10px auto;
}

.copyRightContainer{
    width: 80%;
    margin: 40px auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyRightContainer p,.copyRightContainer a {
    font-size: 18px;
    color: rgba(255,255,255,0.8);
    font-weight: 400;
    opacity: 1;
    position: relative;
    text-decoration: none;
}

.copyRightContainer a{
    cursor: pointer;
}

.copyRightContainer .privacyPolicy{
    display: flex;
    align-items: center;
    gap: 60px;
}

@media screen and (max-width:1200px) {
     .footerColumnContainer{
        grid-template-columns: repeat(3,1fr);
         gap: 10px;
     }
}

@media screen and (max-width:900px) {
     .footerColumnContainer{
        width: 100%;
     }
     .footer{
        padding: 15px;
     }
}
@media screen and (max-width:800px) {
     .footerColumnContainer{
        grid-template-columns: repeat(2,1fr);
      }
     .footer{
        padding: 20px;
     }
     .copyRightContainer div{
        gap: 20px;
     }
     .copyRightContainer p,.copyRightContainer a {
        font-size: 14px;
     }
}
@media screen and (max-width:700px) {
     .footerColumnContainer{
        padding-left: 0px;
      }
           .seperator{
        width: 90%;
     }
     .copyRightContainer{
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
     }
     .copyRightContainer .privacyPolicy{
        justify-content: space-between;
        width: 100%;
     }
}

@media screen and (max-width:560px){
    .footerColumn h2{
        font-size: 18px;
    }
    .footerColumn ul li{
    font-size: 13px;
}
.footer{
        padding: 20px 5px;
     } 

}

@media screen and (max-width:450px){
 .footerColumnContainer{
        grid-template-columns: repeat(1,1fr);
    }
    .footerColumn{
        justify-content: center;
        align-items: center;
    }

    .footerColumn h2{
        text-align: center;
    }

    .footerColumn ul li{
        text-align: center;
    }
    .linkContainer{
        justify-content: center;
    }
}
@media screen and (max-width:320px){

    .footerColumn{
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
    }
    .footerColumn ul li{
        text-align: left;
    }

    .footer .topHeading{
        font-size: 18px;
    }
}
