/* This component has a fixed-size layout */
  .SiteInfo {
    height: auto;
    width: 100%; 
    /*background-color: purple;*/ 
  }

  .SiteInfo .WebSiteName {
    font-size:  30px;
    line-height: 30px;
    color:  white;
    font-weight: 900;
    margin-top: 5%;
    text-align: center;
  }

  .SiteInfo .WebSiteUrl {
    font-size:  12px;
    line-height: 12px;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }


  .SiteInfo .CatchWord {
    font-size:  14px;
    line-height: 14px;
    margin-top: 3%;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }

  .SiteInfo .SubCatchWord {
    font-size:  10px;
    line-height: 10px;
    margin-top: 2%;
    margin-bottom: 3%;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
    margin-left: 1.5%;     
    margin-right: 1.5%;
  }



@media (min-width: 700px) {
  .SiteInfo {
    height: 4%;
    width: 100%; 
  }

  .SiteInfo .WebSiteName {
    font-size:  70px;
    line-height: 70px;
    font-weight: 1000;
    margin-top: 2%;
    text-align: center;
  }

  .SiteInfo .WebSiteUrl {
    font-size:  20px;
    line-height: 20px;
    margin-top: 0.5%;
    font-style: italic;
    text-align: center;
  }

  .SiteInfo .CatchWord {
    font-size:  20px;
    line-height: 20px;
    margin-top: 1%;
    font-style: italic;
    text-align: center;
  }


  .SiteInfo .SubCatchWord {
    font-size:  14px;
    line-height: 14px;
    margin-top: 1%;
    margin-bottom: 2%;
    font-style: italic;
    text-align: center;
  }
}