.TextArea2 {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextArea2Inner{
  width: 98%;
  min-height:85vh;
  background-color: rgba(11, 26, 38, 0.75);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10%;
  margin: 40px auto;
}


@media screen and (max-width:1200px) {
    .TextArea2Inner{
      width: 98%;
    }
}

@media screen and (max-width:968px) {
    .TextArea2Inner{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .TextArea2Inner{
      width: 95%;
    }
}

