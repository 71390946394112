/* This component has a fixed-size layout */
  .topInfoBlock {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: auto;
    width: 100%;     
    /*background-color: purple;*/ 
  }


.topInfoBlock > div {
  margin: 2%;
}


  



@media (max-width: 700px) {
  .topInfoBlock {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 2%;
    height: 4%;
    width: 100%; 
  }

.topInfoBlock > div {
  margin: 1%;
}

  
}