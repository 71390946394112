.List {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-container{
  width: 90%;
  min-height:85vh;
  background-color: rgba(0, 211, 233, 0.1);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10px;
  margin: 40px auto;
  text-align: left;
}

.accordionContentContainer{
  padding-bottom: 10px;
  width: 100%;
  margin-top:50px;
}

@media screen and (max-width:1200px) {
    .accordion-container{
      width: 90%;
    }
}

@media screen and (max-width:968px) {
    .accordion-container{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .accordion-container{
      width: 90%;
    }
}

