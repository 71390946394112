.TextArea {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextAreaInner{
  width: 90%;
  min-height:85vh;
  background-color: rgba(0, 211, 233, 0.1);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10px;
  margin: 40px auto;
}


@media screen and (max-width:1200px) {
    .TextAreaInner{
      width: 90%;
    }
}

@media screen and (max-width:968px) {
    .TextAreaInner{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .TextAreaInner{
      width: 90%;
    }
}

