/* mobile <= 820px
/* test with 390 test with 412, test with 768, test with 280*/
/*optimize for 360 - 440 

mobile - 360 & 440
small mobile <360
large mobile 440>  <=820
desktop >820

*/


.HomePage {
    overflow: scroll;
    height: 100vh;
    min-height: 50vh;
    font-family: Poppins, Roboto, sans-serif;
    background-color: purple;
    background-image: url("./images/alexander-andrews-yOIT88xWkbg-unsplash.jpg");
    /*background-image: url("./images/DALL·E 2022-10-03 00.09.16 - an astronaut riding a dinosaur in a desert.png");*/
    
    background-repeat: no-repeat;
    background-size: cover;
}

/*  =============  HEADER  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .HeaderSection {
        height: auto;
        min-height: 5%;
        background-color: transparent;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .HeaderSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .HeaderSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .HeaderSection {
        background-color: transparent;
    }
}




/*  =============  TOP SITE INFO  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopSiteInfoSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightgreen; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}





/*  =============  TOP CONTENT SECTION ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopContentSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightcoral; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .TopContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopContentSection {
        background-color: transparent;
    }
}


/*  =============  CONTENT CONTAINER  ============= */




xxx

/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .ContentSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightcoral; */
        background-color: transparent;
    }


    .ContentSection .flex_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }

    .ContentSectionInner {
        height: auto;
        padding-left: 20vw;
        padding-right: 20vw;
        /* background-color: lightgreen; */
        background-color: transparent;
        text-align: left;
        margin: 1%;
        color: white;
        align-content: center;
    }

/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .ContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .ContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .ContentSection {
        background-color: transparent;
    }

    .ContentSection .flex_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        width: 60%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        width: 30%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }

}



/*  =============  BOTTOM SITE INFO  ============= */


/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .BottomSiteInfoSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightsteelblue; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}



/*  =============  FOOTER  ============= */



/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .FooterSection {
        height: auto;
        min-height: 5%;
        /* background-color: lightcyan;*/
        background-color: transparent;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .FooterSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .FooterSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .FooterSection {
        background-color: transparent;
    }
}
