body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* mobile <= 820px
/* test with 390 test with 412, test with 768, test with 280*/
/*optimize for 360 - 440 

mobile - 360 & 440
small mobile <360
large mobile 440>  <=820
desktop >820

*/


.HomePage {
    overflow: scroll;
    height: 100vh;
    min-height: 50vh;
    font-family: Poppins, Roboto, sans-serif;
    background-color: purple;
    background-image: url(/static/media/alexander-andrews-yOIT88xWkbg-unsplash.aad2a66a.jpg);
    /*background-image: url("./images/DALL·E 2022-10-03 00.09.16 - an astronaut riding a dinosaur in a desert.png");*/
    
    background-repeat: no-repeat;
    background-size: cover;
}

/*  =============  HEADER  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .HeaderSection {
        height: auto;
        min-height: 5%;
        background-color: transparent;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .HeaderSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .HeaderSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .HeaderSection {
        background-color: transparent;
    }
}




/*  =============  TOP SITE INFO  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopSiteInfoSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightgreen; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}





/*  =============  TOP CONTENT SECTION ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopContentSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightcoral; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .TopContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopContentSection {
        background-color: transparent;
    }
}


/*  =============  CONTENT CONTAINER  ============= */




xxx

/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .ContentSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightcoral; */
        background-color: transparent;
    }


    .ContentSection .flex_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }

    .ContentSectionInner {
        height: auto;
        padding-left: 20vw;
        padding-right: 20vw;
        /* background-color: lightgreen; */
        background-color: transparent;
        text-align: left;
        margin: 1%;
        color: white;
        align-content: center;
    }

/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .ContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .ContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .ContentSection {
        background-color: transparent;
    }

    .ContentSection .flex_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        width: 60%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        width: 30%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }

}



/*  =============  BOTTOM SITE INFO  ============= */


/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .BottomSiteInfoSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightsteelblue; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}



/*  =============  FOOTER  ============= */



/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .FooterSection {
        height: auto;
        min-height: 5%;
        /* background-color: lightcyan;*/
        background-color: transparent;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .FooterSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .FooterSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .FooterSection {
        background-color: transparent;
    }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: rgba(11, 26, 38, 0.75);
  border-radius: 10%;
}

.nav li:hover {
  background-color: #777;
  border-radius: 10%;
}

.seemore {
        display:  none;
    }


@media (max-width: 460px) {
    .home {
        display:  none;
    }
}

@media (min-width: 90px) {
    .addrating {
      display:  none;
    }
}

/* mobile <= 820px
/* test with 390 test with 412, test with 768, test with 280*/
/*optimize for 360 - 440 

mobile - 360 & 440
small mobile <360
large mobile 440>  <=820
desktop >820

*/


.HomePage {
    overflow: scroll;
    height: 100vh;
    min-height: 50vh;
    font-family: Poppins, Roboto, sans-serif;
    background-color: purple;
    background-image: url(/static/media/alexander-andrews-yOIT88xWkbg-unsplash.aad2a66a.jpg);
    /*background-image: url("../images/DALL·E 2022-10-03 00.09.16 - an astronaut riding a dinosaur in a desert.png");*/
    
    background-repeat: no-repeat;
    background-size: cover;
}

/*  =============  HEADER  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .HeaderSection {
        height: auto;
        min-height: 5%;
        background-color: transparent;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .HeaderSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .HeaderSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .HeaderSection {
        background-color: transparent;
    }
}




/*  =============  TOP SITE INFO  ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopSiteInfoSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightgreen; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopSiteInfoSection {
        background-color: transparent;
    }
}





/*  =============  TOP CONTENT SECTION ============= */


/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .TopContentSection {
        height: auto;
        min-height: 10%;
        /* background-color: lightcoral; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
        margin-top: 10%;
    }
/*} */

    .questionsImage {
        width: 90vw;
    }

/* small mobile screen */
@media (max-width: 359px) {
    .TopContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .TopContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .TopContentSection {
        background-color: transparent;
    }
}


/*  =============  CONTENT CONTAINER  ============= */




xxx

/* typical mobile screen 
@media (min-width: 360px) and (max-width: 440px) { */
    .ContentSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightcoral; */
        background-color: transparent;
    }


    .ContentSection .flex_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        min-height: 30%;
        text-align: center;
    }

/*} */


/* small mobile screen */
@media (max-width: 359px) {
    .ContentSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .ContentSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .ContentSection {
        background-color: transparent;
    }

    .ContentSection .flex_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ContentSection .flex_row .LeftContentSection {
        /* background-color: white; */
        background-color: transparent;
        width: 60%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }


    .ContentSection .flex_row .RightContentSection {
        /* background-color: gray; */
        background:transparent;
        width: 30%;
        margin: 2.5%;
        min-height: 30%;
        text-align: center;
    }

}



/*  =============  BOTTOM SITE INFO  ============= */


/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .BottomSiteInfoSection {
        height: auto;
        min-height: 30%;
        /* background-color: lightsteelblue; */
        background-color: transparent;
        text-align: center;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .BottomSiteInfoSection {
        background-color: transparent;
    }
}



/*  =============  FOOTER  ============= */



/* typical mobile screen */
@media (min-width: 360px) and (max-width: 440px) {
    .FooterSection {
        height: auto;
        min-height: 5%;
        /* background-color: lightcyan;*/
        background-color: transparent;
        margin: 1%;
    }
}


/* small mobile screen */
@media (max-width: 359px) {
    .FooterSection {
        background-color: transparent;
    }
}

/* large mobile screen */
@media (min-width: 441px) and (max-width: 820px)  {
    .FooterSection {
        background-color: transparent;
    }
}

/* desktop screen */
@media (min-width: 821px) {
    .FooterSection {
        background-color: transparent;
    }
}

.popup_popup__gI-Fp{
     width: 360px;
     position: absolute;
     height: 180px;
     right: 0;
     top: -200px;
     background-color: #d9d9d9;
     display: flex;
     /* align-items: center; */
     justify-content: center;
     flex-direction: column;
     border-radius: 10px;
     grid-gap: 10px;
     gap: 10px;
     transition: all 150ms ease;
     pointer-events: none;
     opacity: 0;
}

.popup_popup__gI-Fp[aria-role="true"]{
    opacity: 1;
    transition: all 150ms ease;
    pointer-events: all;
}

.popup_popup__gI-Fp p{
    /* line-height: 0; */
    color: #000000;
    font-size: 16px;
}

.popup_popup__gI-Fp .popup_email__3eyEk{
    margin: -0px auto;
    /* text-align: left; */
    color: #000000;
    margin-bottom: 30px;

}

.popup_popup__gI-Fp .popup_contactHeading__2aMYZ{
    margin: 0px auto;
    color: #000000;

    text-align: center;
}

@media screen and (max-width:800px) {

    .popup_popup__gI-Fp{
        width: 320px;
        height: 160px;
        top: -180px;
    }

    .popup_popup__gI-Fp .popup_contactHeading__2aMYZ{
    margin: -10px auto;

    text-align: left;
}
}

@media screen and (max-width:450px){
     .popup_popup__gI-Fp{
        width: 260px;
        height: 130px;
        top: -140px;
    }
}
.footer_footer__3avFj{
    background:  rgba(1, 9, 16, 0.9);
    width:100%;
    margin: 0;
    padding: 20px;
}

.footer_footer__3avFj .footer_topHeading__2DWad{
    font-weight: 700;
    letter-spacing: 1.05px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-top:20px;
    text-align: center;
}

.footer_footerColumnContainer__2QEPT{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    gap: 10px;
    place-items: center;
    width: 90%;
    margin: 50px auto;
    /* background-color: antiquewhite; */
    max-width: 1800px;
}

.footer_footerColumn__10eIn{
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
}

.footer_footerColumn__10eIn h2{
   color: #fff;
   font-size: 20px;
   font-weight: 600;
   margin: 16px auto;
   text-align: left;
}

.footer_footerColumn__10eIn ul{
    list-style: none;
    margin-top: 5px;

}

.footer_footerColumn__10eIn ul li{
    color: #fff;
    font-weight: 400;
    padding: 3px 0px;
    font-size: 16px;
}

.footer_linkContainer__ULftE{
    margin: 80px auto 50px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 85%;
    max-width: 1700px;
    
}

.footer_linkContainer__ULftE a{
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}

.footer_linkContainer__ULftE a:before{
    content: "";
   position: absolute;
   bottom: 2px;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: #ffffff;
   opacity: 0.6;

}

.footer_seperator__SwSKK{
    width: 80%;
       height: 3px;
   background-color: #ffffff;
   margin: 30px auto 10px auto;
}

.footer_copyRightContainer__QVneJ{
    width: 80%;
    margin: 40px auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_copyRightContainer__QVneJ p,.footer_copyRightContainer__QVneJ a {
    font-size: 18px;
    color: rgba(255,255,255,0.8);
    font-weight: 400;
    opacity: 1;
    position: relative;
    text-decoration: none;
}

.footer_copyRightContainer__QVneJ a{
    cursor: pointer;
}

.footer_copyRightContainer__QVneJ .footer_privacyPolicy__2kPH5{
    display: flex;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
}

@media screen and (max-width:1200px) {
     .footer_footerColumnContainer__2QEPT{
        grid-template-columns: repeat(3,1fr);
         grid-gap: 10px;
         gap: 10px;
     }
}

@media screen and (max-width:900px) {
     .footer_footerColumnContainer__2QEPT{
        width: 100%;
     }
     .footer_footer__3avFj{
        padding: 15px;
     }
}
@media screen and (max-width:800px) {
     .footer_footerColumnContainer__2QEPT{
        grid-template-columns: repeat(2,1fr);
      }
     .footer_footer__3avFj{
        padding: 20px;
     }
     .footer_copyRightContainer__QVneJ div{
        grid-gap: 20px;
        gap: 20px;
     }
     .footer_copyRightContainer__QVneJ p,.footer_copyRightContainer__QVneJ a {
        font-size: 14px;
     }
}
@media screen and (max-width:700px) {
     .footer_footerColumnContainer__2QEPT{
        padding-left: 0px;
      }
           .footer_seperator__SwSKK{
        width: 90%;
     }
     .footer_copyRightContainer__QVneJ{
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 10px;
        gap: 10px;
     }
     .footer_copyRightContainer__QVneJ .footer_privacyPolicy__2kPH5{
        justify-content: space-between;
        width: 100%;
     }
}

@media screen and (max-width:560px){
    .footer_footerColumn__10eIn h2{
        font-size: 18px;
    }
    .footer_footerColumn__10eIn ul li{
    font-size: 13px;
}
.footer_footer__3avFj{
        padding: 20px 5px;
     } 

}

@media screen and (max-width:450px){
 .footer_footerColumnContainer__2QEPT{
        grid-template-columns: repeat(1,1fr);
    }
    .footer_footerColumn__10eIn{
        justify-content: center;
        align-items: center;
    }

    .footer_footerColumn__10eIn h2{
        text-align: center;
    }

    .footer_footerColumn__10eIn ul li{
        text-align: center;
    }
    .footer_linkContainer__ULftE{
        justify-content: center;
    }
}
@media screen and (max-width:320px){

    .footer_footerColumn__10eIn{
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
    }
    .footer_footerColumn__10eIn ul li{
        text-align: left;
    }

    .footer_footer__3avFj .footer_topHeading__2DWad{
        font-size: 18px;
    }
}

/* This component has a fixed-size layout */
  .SiteInfo {
    height: auto;
    width: 100%; 
    /*background-color: purple;*/ 
  }

  .SiteInfo .WebSiteName {
    font-size:  30px;
    line-height: 30px;
    color:  white;
    font-weight: 900;
    margin-top: 5%;
    text-align: center;
  }

  .SiteInfo .WebSiteUrl {
    font-size:  12px;
    line-height: 12px;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }


  .SiteInfo .CatchWord {
    font-size:  14px;
    line-height: 14px;
    margin-top: 3%;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }

  .SiteInfo .SubCatchWord {
    font-size:  10px;
    line-height: 10px;
    margin-top: 2%;
    margin-bottom: 3%;
    color:  white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
    margin-left: 1.5%;     
    margin-right: 1.5%;
  }



@media (min-width: 700px) {
  .SiteInfo {
    height: 4%;
    width: 100%; 
  }

  .SiteInfo .WebSiteName {
    font-size:  70px;
    line-height: 70px;
    font-weight: 1000;
    margin-top: 2%;
    text-align: center;
  }

  .SiteInfo .WebSiteUrl {
    font-size:  20px;
    line-height: 20px;
    margin-top: 0.5%;
    font-style: italic;
    text-align: center;
  }

  .SiteInfo .CatchWord {
    font-size:  20px;
    line-height: 20px;
    margin-top: 1%;
    font-style: italic;
    text-align: center;
  }


  .SiteInfo .SubCatchWord {
    font-size:  14px;
    line-height: 14px;
    margin-top: 1%;
    margin-bottom: 2%;
    font-style: italic;
    text-align: center;
  }
}
/* This component has a fixed-size layout */
  .topInfoBlock {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: auto;
    width: 100%;     
    /*background-color: purple;*/ 
  }


.topInfoBlock > div {
  margin: 2%;
}


  



@media (max-width: 700px) {
  .topInfoBlock {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 2%;
    height: 4%;
    width: 100%; 
  }

.topInfoBlock > div {
  margin: 1%;
}

  
}
.footer_footer__3lNwa{
    background:  rgba(1, 9, 16, 0.9);
    min-height:400px;
    width:100%;
    margin: 0;
    padding: 20px;
}

.footer_footer__3lNwa .footer_topHeading__2jiEA{
    font-weight: 700;
    letter-spacing: 1.05px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-top:20px;
    text-align: center;
}

.footer_footerColumnContainer__34Uk9{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    gap: 10px;
    place-items: center;
    width: 90%;
    margin: 50px auto;
    /* background-color: antiquewhite; */
    max-width: 1800px;

}

.footer_footerColumn__1lxgP{
    color: floralwhite;
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    flex-direction: column;
    text-align: left;
    /* background-color: red; */
}

.footer_footerColumn__1lxgP h2{
   color: #fff;
   font-size: 20px;
   font-weight: 600;
   margin: 16px auto;
   text-align: left;
}

.footer_footerColumn__1lxgP ul{
    list-style: none;
    margin-top: 5px;

}

.footer_footerColumn__1lxgP ul li{
    color: #fff;
    font-weight: 400;
    padding: 3px 0px;
    font-size: 16px;
}

.footer_linkContainer__2yNld{
    margin: 80px auto 50px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 85%;
    max-width: 1700px;
    
}

.footer_linkContainer__2yNld a{
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}

.footer_linkContainer__2yNld a:before{
    content: "";
   position: absolute;
   bottom: 2px;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: #ffffff;
   opacity: 0.6;

}

.footer_seperator__25dm2{
    width: 80%;
       height: 3px;
   background-color: #ffffff;
   margin: 30px auto 10px auto;
}

.footer_copyRightContainer__3R8ds{
    width: 80%;
    margin: 40px auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_copyRightContainer__3R8ds p,.footer_copyRightContainer__3R8ds a {
    font-size: 18px;
    color: rgba(255,255,255,0.8);
    font-weight: 400;
    opacity: 1;
    position: relative;
    text-decoration: none;
}

.footer_copyRightContainer__3R8ds a{
    cursor: pointer;
}

.footer_copyRightContainer__3R8ds .footer_privacyPolicy__2wzEl{
    display: flex;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
}

@media screen and (max-width:1200px) {
     .footer_footerColumnContainer__34Uk9{
        grid-template-columns: repeat(3,1fr);
         grid-gap: 10px;
         gap: 10px;
     }
}

@media screen and (max-width:900px) {
     .footer_footerColumnContainer__34Uk9{
        width: 100%;
     }
     .footer_footer__3lNwa{
        padding: 15px;
     }
}
@media screen and (max-width:800px) {
     .footer_footerColumnContainer__34Uk9{
        grid-template-columns: repeat(2,1fr);
      }
     .footer_footer__3lNwa{
        padding: 20px;
     }
     .footer_copyRightContainer__3R8ds div{
        grid-gap: 20px;
        gap: 20px;
     }
     .footer_copyRightContainer__3R8ds p,.footer_copyRightContainer__3R8ds a {
        font-size: 14px;
     }
}
@media screen and (max-width:700px) {
     .footer_footerColumnContainer__34Uk9{
        padding-left: 0px;
      }
           .footer_seperator__25dm2{
        width: 90%;
     }
     .footer_copyRightContainer__3R8ds{
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 10px;
        gap: 10px;
     }
     .footer_copyRightContainer__3R8ds .footer_privacyPolicy__2wzEl{
        justify-content: space-between;
        width: 100%;
     }
}

@media screen and (max-width:560px){
    .footer_footerColumn__1lxgP h2{
        font-size: 18px;
    }
    .footer_footerColumn__1lxgP ul li{
    font-size: 13px;
}
.footer_footer__3lNwa{
        padding: 20px 5px;
     } 

}

@media screen and (max-width:450px){
 .footer_footerColumnContainer__34Uk9{
        grid-template-columns: repeat(1,1fr);
    }
    .footer_footerColumn__1lxgP{
        justify-content: center;
        align-items: center;
    }

    .footer_footerColumn__1lxgP h2{
        text-align: center;
    }

    .footer_footerColumn__1lxgP ul li{
        text-align: center;
    }
    .footer_linkContainer__2yNld{
        justify-content: center;
    }
}
@media screen and (max-width:320px){

    .footer_footerColumn__1lxgP{
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
    }
    .footer_footerColumn__1lxgP ul li{
        text-align: left;
    }

    .footer_footer__3lNwa .footer_topHeading__2jiEA{
        font-size: 18px;
    }
}

.List {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-container{
  width: 90%;
  min-height:85vh;
  background-color: rgba(0, 211, 233, 0.1);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10px;
  margin: 40px auto;
  text-align: left;
}

.accordionContentContainer{
  padding-bottom: 10px;
  width: 100%;
  margin-top:50px;
}

@media screen and (max-width:1200px) {
    .accordion-container{
      width: 90%;
    }
}

@media screen and (max-width:968px) {
    .accordion-container{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .accordion-container{
      width: 90%;
    }
}


.accordion_accordion__1VkPH {
  width: 95%;
  background: rgba(11, 26, 38, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 10px 15px;
  margin: 15px auto;
  min-height: 80px;
}

.accordion_wrapper__2S3dq {
  display: grid;

  width: 100%;

  place-items: center;
  place-content: center;
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  min-height: 80px;
}

.accordion_titleContainer__1tacL {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.accordion_titleContainer__1tacL .accordion_gradientDiv__j6f2s {
  width: 65px;
  height: 65px;
  border-radius: 18px;
  display: flex;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.accordion_titleDiv__38Exw {
  margin-left: 8px;
}

.accordion_titleDiv__38Exw h3 {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin: 7px auto;
}
.accordion_titleDiv__38Exw p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin: 7px auto;
}

.accordion_ratingContainer__1ib0p {
  width: 270px;
  /* padding: 3px; */
  margin-left: 20px;
  margin-top: -14px;
}

.accordion_ratingContainer__1ib0p .accordion_ratingStat__33UTz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accordion_ratingStat__33UTz p,
.accordion_ratingStat__33UTz span {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.accordion_ratingBar__2uCWs {
  width: 100%;
  height: 8px;
  border-radius: 20px;
  margin-top: 2px;
  background: rgba(136, 136, 136, 0.1);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.accordion_ratingBarFilled__2RDeA {
  background-color: rgba(0, 211, 233, 1);
  height: 100%;
  border-radius: 20px;
}

.accordion_collapseButtonContainer__2ldqd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.accordion_collapseButton__3p5DX {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  height: 40px;
  background: rgba(1, 9, 16, 0.9);
  border-radius: 8px;
  cursor: pointer;
}

.accordion_collapseButton__3p5DX p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.accordion_collapseButton__3p5DX img {
  padding-right: 14px;
  margin-left: 8px;
  transition: all 0.35s ease;
  width: 15px;
  height: 15px;
  align-self: center;
}

.accordion_collapseButton__3p5DX img[aria-expanded="true"] {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.accordion_accordionContent__3MgHE {
  overflow-y: hidden;
  max-height: 10000px;
  width: 100%;
  transition: max-height 1s ease-in-out;
}

.accordion_accordionContent__3MgHE[aria-expanded="true"] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

@media screen and (max-width: 900px) {
  .accordion_ratingContainer__1ib0p {
    padding-left: 2%;
    width: 90%;
    margin-left: 0px;

  }
  .accordion_collapseButtonContainer__2ldqd {
    width: 80%;
  }
  .accordion_wrapper__2S3dq{
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      place-items: left;
      place-content: left;
      grid-gap: 15px;
      gap: 15px;
  }
  .accordion_titleContainer__1tacL{
    grid-column: 1 / span 2;
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
.accordion_wrapper__2S3dq{
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .accordion_collapseButtonContainer__2ldqd {
    width: 140px;
  }

  .accordion_accordion__1VkPH {
    width: 90%;
    padding: 8px 10px;
  }
}


@media screen and (max-width: 420px) {
.accordion_wrapper__2S3dq{
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .accordion_ratingContainer__1ib0p {
    grid-column: 1 / span 2;

  }

  .accordion_titleDiv__38Exw h3 {
    font-size: 13px;
  }

  .accordion_titleDiv__38Exw p {
    font-size: 13px;
  }

.accordion_collapseButtonContainer__2ldqd {
  width: 90%;
}
 
  .accordion_collapseButton__3p5DX{
    width: 110px;
  }

  .accordion_collapseButton__3p5DX p{
     font-size: 12px;
  }

  .accordion_collapseButton__3p5DX img{
    padding-right: 10px;
  }


  .accordion_titleContainer__1tacL .accordion_gradientDiv__j6f2s{
    width: 50px;
    height: 50px;
  }

.accordion_ratingStat__33UTz p{
    font-size: 12px;
}


}

/* small mobile screen */
@media (max-width: 359px) {
  .accordion_accordion__1VkPH{
    width: 95%;
  }
   .accordion_ratingContainer__1ib0p {
    width: 90%;
    margin-left: 0px;

  }
  .accordion_collapseButtonContainer__2ldqd {
    width: 60%;
  }

  .accordion_titleContainer__1tacL {
    width: 80%;
  }

  .accordion_titleContainer__1tacL .accordion_gradientDiv__j6f2s {
  width: 30px;
  height: 30px;
  border-radius: 18px;
  display: flex;
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.accordion_ratingBar__2uCWs {
  padding-left: 10%;
}

}
.questionBox_questionBox__20aM5{
    width: 90%;
    margin: 10px auto;
    padding: 6px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    height: 60px;
}

.questionBox_questionBox__20aM5 .questionBox_questionText__A8Oe2{
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.questionBox_questionBox__20aM5 .questionBox_booleanClass__3NbO4{
    font-weight: 500;
    font-size: 16px;
    color: rgba(208, 43, 7, 1);
}
.questionBox_questionBox__20aM5 .questionBox_booleanClass__3NbO4[aria-role="true"]{
    color:rgba(15, 221, 35, 1);
}

@media screen and (max-width:600px) {

    .questionBox_questionBox__20aM5{
    width: 95%;
    padding: 4px 10px;
    grid-gap: 10;
    gap: 10;
    }

    .questionBox_questionBox__20aM5 .questionBox_booleanClass__3NbO4{
    font-size: 13px;
    }

   .questionBox_questionBox__20aM5 .questionBox_questionText__A8Oe2{
    font-weight: 500;
    font-size: 13px;
    color: #fff;
    width: 85%;

}

}
.TextArea {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextAreaInner{
  width: 90%;
  min-height:85vh;
  background-color: rgba(0, 211, 233, 0.1);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10px;
  margin: 40px auto;
}


@media screen and (max-width:1200px) {
    .TextAreaInner{
      width: 90%;
    }
}

@media screen and (max-width:968px) {
    .TextAreaInner{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .TextAreaInner{
      width: 90%;
    }
}


.TextArea2 {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextArea2Inner{
  width: 98%;
  min-height:85vh;
  background-color: rgba(11, 26, 38, 0.75);
  border: 1px solid rgba(152, 150, 240, 0.9);
  border-radius:20px;
  padding: 30px 10%;
  margin: 40px auto;
}


@media screen and (max-width:1200px) {
    .TextArea2Inner{
      width: 98%;
    }
}

@media screen and (max-width:968px) {
    .TextArea2Inner{
      width: 95%;
    }
}

@media screen and (max-width:450px) {
    .TextArea2Inner{
      width: 95%;
    }
}


.jobboard_footer__3ykvg{
    background:  transparent;
    min-height:400px;
    width:100%;
    margin: 0;
    padding: 20px;
}

.jobboard_footer__3ykvg .jobboard_topHeading__1WAiF{
    font-weight: 700;
    letter-spacing: 1.05px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-top:20px;
    text-align: center;
}

.jobboard_footerColumnContainer__2bKeb{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    gap: 10px;
    place-items: center;
    width: 90%;
    margin: 50px auto;
    /* background-color: antiquewhite; */
    max-width: 1800px;
     /* background-color: red;*/
}

.jobboard_footerColumn__2oRr7{
    color: floralwhite;
    font-weight:bold;
    font-size: 1.2rem;
    text-align: left;
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
}

.jobboard_footerColumn__2oRr7 h2{
   color: #221f1a;
   font-size: 20px;
   font-weight: 600;
   margin: 16px auto;
   text-align: left;
}

.jobboard_footerColumn__2oRr7 ul{
    list-style: none;
    margin-top: 5px;
}





.jobboard_footerColumn__2oRr7 ul li{
    color: #fff;
    font-weight: 400;
    padding: 3px 0px;
    font-size: 16px;
}

.jobboard_linkContainer__3NOK0{
    margin: 80px auto 50px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 85%;
    max-width: 1700px;
    
}

.jobboard_linkContainer__3NOK0 a{
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}


.jobboard_linkContainer__3NOK0 a:before{
    content: "";
   position: absolute;
   bottom: 2px;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: #ffffff;
   opacity: 0.6;

}

.jobboard_seperator__2CTPK{
    width: 80%;
       height: 3px;
   background-color: #ffffff;
   margin: 30px auto 10px auto;
}

.jobboard_copyRightContainer__3lOXJ{
    width: 80%;
    margin: 40px auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jobboard_copyRightContainer__3lOXJ p,.jobboard_copyRightContainer__3lOXJ a {
    font-size: 18px;
    color: rgba(255,255,255,0.8);
    font-weight: 400;
    opacity: 1;
    position: relative;
    text-decoration: none;
}

.jobboard_copyRightContainer__3lOXJ a{
    cursor: pointer;
}

.jobboard_copyRightContainer__3lOXJ .jobboard_privacyPolicy__j8YBN{
    display: flex;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
}

@media screen and (max-width:1200px) {
     .jobboard_footerColumnContainer__2bKeb{
        grid-template-columns: repeat(3,1fr);
         grid-gap: 10px;
         gap: 10px;
     }
}

@media screen and (max-width:900px) {
     .jobboard_footerColumnContainer__2bKeb{
        width: 100%;
     }
     .jobboard_footer__3ykvg{
        padding: 15px;
     }
}
@media screen and (max-width:800px) {
     .jobboard_footerColumnContainer__2bKeb{
        grid-template-columns: repeat(2,1fr);
      }
     .jobboard_footer__3ykvg{
        padding: 20px;
     }
     .jobboard_copyRightContainer__3lOXJ div{
        grid-gap: 20px;
        gap: 20px;
     }
     .jobboard_copyRightContainer__3lOXJ p,.jobboard_copyRightContainer__3lOXJ a {
        font-size: 14px;
     }
}
@media screen and (max-width:700px) {
     .jobboard_footerColumnContainer__2bKeb{
        padding-left: 0px;
      }
           .jobboard_seperator__2CTPK{
        width: 90%;
     }
     .jobboard_copyRightContainer__3lOXJ{
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 10px;
        gap: 10px;
     }
     .jobboard_copyRightContainer__3lOXJ .jobboard_privacyPolicy__j8YBN{
        justify-content: space-between;
        width: 100%;
     }
}

@media screen and (max-width:560px){
    .jobboard_footerColumn__2oRr7 h2{
        font-size: 18px;
    }
    .jobboard_footerColumn__2oRr7 ul li{
    font-size: 13px;
}
.jobboard_footer__3ykvg{
        padding: 20px 5px;
     } 

}

@media screen and (max-width:450px){
 .jobboard_footerColumnContainer__2bKeb{
        grid-template-columns: repeat(1,1fr);
    }
    .jobboard_footerColumn__2oRr7{
        justify-content: center;
        align-items: center;
    }

    .jobboard_footerColumn__2oRr7 h2{
        text-align: center;
    }

    .jobboard_footerColumn__2oRr7 ul li{
        text-align: center;
    }
    .jobboard_linkContainer__3NOK0{
        justify-content: center;
    }
}
@media screen and (max-width:320px){

    .jobboard_footerColumn__2oRr7{
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
    }
    .jobboard_footerColumn__2oRr7 ul li{
        text-align: left;
    }

    .jobboard_footer__3ykvg .jobboard_topHeading__1WAiF{
        font-size: 18px;
    }
}

