* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: rgba(11, 26, 38, 0.75);
  border-radius: 10%;
}

.nav li:hover {
  background-color: #777;
  border-radius: 10%;
}

.seemore {
        display:  none;
    }


@media (max-width: 460px) {
    .home {
        display:  none;
    }
}

@media (min-width: 90px) {
    .addrating {
      display:  none;
    }
}
